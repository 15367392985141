import React from 'react';
import { graphql } from 'gatsby';
import Layout from '~/components/Layout';
import Metadata from '~/components/Metadata';
import ProductPage from '~/components/Product/Page';

const ProductTemplate = ({
  location,
  data: { product, notifications, membersOnlyContent, productCollection },
  notifyOOO,
  notifyComingSoon,
}) => {
  const collectionArray = product.collection;

  const price = product.price ?? product.variants?.[0].price;

  const collection = collectionArray?.find((c) => location.pathname.includes(c.slug));

  return (
    <Layout navColor={true}>
      <Metadata
        collection={`${collection.name} Gravity Sportswear`}
        title={product.metaTitle ?? product.name}
        description={product.metaDescription ?? product.shortDescription}
        image={product.variants?.[0]?.mainImage.file?.url}
      >
        <script type="application/ld+json">
          {`{
    "@context": "https://schema.org",
    "@type": "Product",
    "description":
      "${product.metaDescription ?? product.shortDescription}",
    "name": "${product.metaTitle ?? product.name}",
    "image": "${product.variants?.[0]?.mainImage.file?.url}",
    "offers": {
      "@type": "Offer",
      "availability": "https://schema.org/InStock",
      "price": "${price}",
      "priceCurrency": "USD"
    }
  }`}
        </script>
      </Metadata>

      <ProductPage
        membersOnlyContent={membersOnlyContent}
        notifications={notifications}
        location={location}
        product={product}
        collection={collection}
        notifyComingSoon={notifyComingSoon}
        notifyOOO={notifyOOO}
        productCollection={productCollection?.nodes}
      />
    </Layout>
  );
};

export const query = graphql`
  query ProductPage($slug: String, $locale: String) {
    membersOnlyContent: contentfulCatalog(slug: { eq: "catalog" }, node_locale: { eq: $locale }) {
      ...MembersOnlyFragment
    }
    product: contentfulProduct(slug: { eq: $slug }, node_locale: { eq: $locale }) {
      ...ProductPageFragment
    }
    notifications: contentfulSiteMetadata(slug: { eq: "site-metadata" }) {
      pdpNotifications {
        ...NotificationFragment
      }
    }
    productCollection: allContentfulProduct(
      filter: {
        collection: { elemMatch: { products: { elemMatch: { slug: { eq: $slug } } } } }
        hideOnShopAll: { ne: true }
      }
    ) {
      nodes {
        ...ProductCollection
      }
    }
  }
`;

export default ProductTemplate;
